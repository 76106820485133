import { faYoutube } from '@fortawesome/free-brands-svg-icons';
import { faArrowRight, faTrophy } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import _ from 'lodash';
import React, { useEffect, useState } from 'react';
import PhotoVideo from '~/src/components/shared/PhotoVideo';
import Urls from '~/src/constants/urls';
import {
	imgBrandsDraftKingsSvg,
	imgIconDollarSignSvg,
	imgPlaceholdersFighterFemaleBlueSideWebp,
	imgPlaceholdersFighterFemaleRedSideWebp,
	imgPlaceholdersFighterMaleBlueSideWebp,
	imgPlaceholdersFighterMaleRedSideWebp,
} from '~/src/images';
import { GetDateTimeString, IsInProgress, IsUpcoming } from '~/src/models/OtxEvent';
import {
	EarlyFightStopReasons,
	FightTypes,
	FightWinByTypes,
	GetFightResultsDisplayText,
	GetOddsDisplayText,
	GetTournamentRound,
	IsFightComplete,
	IsLoser,
	IsMoneyRoundWin,
	IsWinner,
	OtxFight,
} from '~/src/models/OtxFight';
import { Genders, OtxFighter } from '~/src/models/OtxFighter';
import { EventInfoResponse, SaleStatuses } from '~/src/models/Vivenu';
import useFetch from '~/src/overtime-lib/src/www/hooks/useFetch';
import { RoutePaths } from '~/src/www/routes';
import './FightCard.scss';
import FontShrinker from './FontShrinker';

const getFighterImageUrl = (otx_fighter: OtxFighter, opponent: OtxFighter, color: string = 'red') => {
	if (!otx_fighter && !opponent) {
		return color == 'red' ? imgPlaceholdersFighterMaleRedSideWebp : imgPlaceholdersFighterMaleBlueSideWebp;
	}
	if (!otx_fighter && opponent.gender === Genders.Male) {
		return color == 'red' ? imgPlaceholdersFighterMaleRedSideWebp : imgPlaceholdersFighterMaleBlueSideWebp;
	}
	if (!otx_fighter && opponent.gender === Genders.Female) {
		return color == 'red' ? imgPlaceholdersFighterFemaleRedSideWebp : imgPlaceholdersFighterFemaleBlueSideWebp;
	}
	if (color == 'blue' && otx_fighter.hero_image_2_path) {
		return `${Urls.OvertimeImagesOtx}/${otx_fighter.hero_image_2_path}`;
	}
	if (otx_fighter.hero_image_path) {
		return `${Urls.OvertimeImagesOtx}/${otx_fighter.hero_image_path}`;
	}
	if (otx_fighter.image_path) {
		return `${Urls.OvertimeImagesOtx}/${otx_fighter.image_path}`;
	}
	if (color === 'red' && (otx_fighter.gender === Genders.Male || opponent.gender === Genders.Male)) {
		return imgPlaceholdersFighterMaleRedSideWebp;
	}
	if (color === 'red' && (otx_fighter.gender === Genders.Female || opponent.gender === Genders.Female)) {
		return imgPlaceholdersFighterFemaleRedSideWebp;
	}
	if (color === 'red') {
		return imgPlaceholdersFighterMaleRedSideWebp;
	}
	if (color === 'blue' && (otx_fighter.gender === Genders.Male || opponent.gender === Genders.Male)) {
		return imgPlaceholdersFighterMaleBlueSideWebp;
	}
	if (color === 'blue' && (otx_fighter.gender === Genders.Female || opponent.gender === Genders.Female)) {
		return imgPlaceholdersFighterFemaleBlueSideWebp;
	}
	if (color === 'blue') {
		return imgPlaceholdersFighterMaleBlueSideWebp;
	}

	return imgPlaceholdersFighterMaleBlueSideWebp;
};

const _FighterNameAndProfile = ({ fighter, side }: { fighter: OtxFighter; side: 'left' | 'right' }) => {
	return (
		<div className={`name${side == 'right' ? ' name--right' : ''}`}>
			{fighter ? (
				<>
					{fighter?.nick_name ? (
						<FontShrinker className="nick-name">"{fighter?.nick_name}"</FontShrinker>
					) : (
						<FontShrinker className="nick-name">&nbsp;</FontShrinker>
					)}

					{fighter?.first_name ? (
						<FontShrinker className="first-name">{fighter.first_name}</FontShrinker>
					) : (
						<FontShrinker className="first-name">&nbsp;</FontShrinker>
					)}

					{fighter?.last_name ? (
						<FontShrinker className="last-name">{fighter.last_name}</FontShrinker>
					) : (
						<FontShrinker className="last-name">&nbsp;</FontShrinker>
					)}

					{fighter?.is_active ? (
						<div className="name-link">
							<a href={`${RoutePaths.Fighters}/${fighter.id}`}>View Profile</a>
						</div>
					) : null}
				</>
			) : (
				<FontShrinker className="first-name">TBD</FontShrinker>
			)}
		</div>
	);
};

const _FighterNameAndProfileMobile = ({ fighter }: { fighter: OtxFighter }) => {
	return (
		<>
			{!fighter ? (
				<div className="fighter-name fighter-name--tbd">TBD</div>
			) : fighter.is_active ? (
				<a className="fighter-name" href={`${RoutePaths.Fighters}/${fighter.id}`}>
					{fighter.first_name?.at(0)}. {fighter.last_name}
				</a>
			) : (
				<div className="fighter-name">
					{fighter.first_name?.at(0)}. {fighter.last_name}
				</div>
			)}
		</>
	);
};

const _FighterPhoto = ({
	fighter,
	opponent,
	fight,
	side = 'left',
}: {
	fighter: OtxFighter;
	opponent: OtxFighter;
	fight: OtxFight;
	side: 'left' | 'right';
}) => {
	const mirrorBlueImage =
		side == 'right' &&
		(getFighterImageUrl(fighter, opponent, 'blue').includes(imgPlaceholdersFighterMaleBlueSideWebp) ||
			getFighterImageUrl(fighter, opponent, 'blue').includes(imgPlaceholdersFighterFemaleBlueSideWebp));

	return (
		<div className="profile">
			<div className={`profile-image-container ${mirrorBlueImage ? ' profile-image-container--mirror' : ''}`}>
				<PhotoVideo
					className={`profile-image  ${IsLoser(fight, fighter) ? 'profile-image--loser' : ''}`}
					src={getFighterImageUrl(fighter, opponent, side == 'left' ? 'red' : 'blue')}
					mobileWidth={285}
				/>
			</div>
		</div>
	);
};

// NOTE: _DraftkingsBettingWidget is designed to be rendered below the CTA buttons component at the bottom of the fight card.
const _DraftkingsBettingWidget = ({ fight }: { fight: OtxFight }) => {
	// if fight is complete or doesn't have a draftkings_fight_id we don't want to show the widget
	if (IsFightComplete(fight) || !fight.draftkings_fight_id) return null;

	return (
		<div className="draftkings-container">
			<div className="odds">
				<a href={Urls.DraftKingsOtxBoxing}>{GetOddsDisplayText(fight.draftkings_fighter1_odds)}</a>
			</div>
			<div className="draftkings-cta-container">
				<a className="draftkings-logo-container" href={Urls.DraftKingsOtxBoxing}>
					<img src={imgBrandsDraftKingsSvg} alt="Draftkings logo" className="draftkings-logo" />
				</a>
				<div className="vertical-line"></div>
				<a className="bet-now" href={Urls.DraftKingsOtxBoxing}>
					<p>Bet now</p>
					<FontAwesomeIcon icon={faArrowRight} />
				</a>
			</div>
			<div className="odds">
				<a href={Urls.DraftKingsOtxBoxing}>{GetOddsDisplayText(fight.draftkings_fighter2_odds)}</a>
			</div>
		</div>
	);
};

const FightCard = ({
	fight,
	tournamentFights,
	displayOtxFightTitle = false,
	displayFightCtaButtons = false,
}: {
	fight: OtxFight;
	tournamentFights: OtxFight[];
	displayOtxFightTitle?: boolean;
	displayFightCtaButtons?: boolean;
}) => {
	const fighter1 = fight.otx_fighter1;
	const fighter2 = fight.otx_fighter2;
	const moneyRoundWin = IsMoneyRoundWin(fight);
	const fightResult = GetFightResultsDisplayText(fight);

	const vivenuEvent = useFetch<EventInfoResponse>({
		url: `${Urls.ApiPlatformsVivenuEventInfo}/${fight.otx_event?.vivenu_id}`,
		key: 'event',
	})?.result.read();
	const isVivenuEventIsSoldOut = vivenuEvent?.saleStatus === SaleStatuses.SoldOut;
	const isEventSoldOut = fight.otx_event?.is_sold_out || isVivenuEventIsSoldOut;

	// Used to display round info if fight didn't go to a decision (not shown for fights that go to decision)
	const displayRoundInfoInResults =
		EarlyFightStopReasons.includes(fight.win_by as FightWinByTypes) && fight.ending_round_number;

	const [fightDate, setFightTime] = useState<string>(
		GetDateTimeString(new Date(fight.otx_event?.starts_at), undefined, false, true, false),
	);

	useEffect(() => {
		setFightTime(GetDateTimeString(new Date(fight.otx_event?.starts_at), undefined, false, true, false));
	}, [fight.starts_at]);

	return (
		<section className="fight-card" key={fight.id}>
			{/*TITLE INFO*/}
			{displayOtxFightTitle && fight.otx_event?.otx_number ? (
				<div
					className={`fight-title fight-title--otx ${IsFightComplete(fight) ? 'fight-tile--black' : 'fight-title--blue'}`}
				>
					<div className="otx-title">OTX {fight.otx_event?.otx_number}</div>
					<div className="date">{fightDate}</div>
				</div>
			) : // TODO: Once non_otx_fight is added to DB we can add logic to display non_otx_fight title here
			fight.is_tournament_fight ? (
				<div className={`fight-title ${IsFightComplete(fight) ? 'fight-tile--black' : 'fight-title--blue'}`}>
					<div>Tournament : {GetTournamentRound(tournamentFights, fight)}</div>
				</div>
			) : fight.fight_type === FightTypes.MainEvent ? (
				<div className={`fight-title ${IsFightComplete(fight) ? 'fight-tile--black' : 'fight-title--blue'}`}>
					<div>{fight.fight_type}</div>
					{fight.title_bout_name ? <div className="fight-title-bout">{fight.title_bout_name}</div> : null}
				</div>
			) : !_.isNil(fight.fight_type) ? (
				<div className={`fight-title ${IsFightComplete(fight) ? 'fight-tile--black' : 'fight-title--blue'}`}>
					<div>{fight.fight_type}</div>
					{fight.title_bout_name ? (
						<div className="fight-title-bout">{fight.title_bout_name && <div>{fight.title_bout_name}</div>}</div>
					) : null}
				</div>
			) : (
				<div className={`fight-title ${IsFightComplete(fight) ? 'fight-tile--black' : 'fight-title--blue'}`}>
					<div className="otx-title">{fight.title_bout_name}</div>
					<div className="date">{GetDateTimeString(new Date(fight.starts_at), undefined, false, true, false)}</div>
				</div>
			)}

			{/*Fight Results*/}
			{IsFightComplete(fight) && (
				<div className="fight-results">
					{/* TODO: can probably simplify this to be conditionally styled to place the winner indicator where it needs to go*/}
					{IsWinner(fight, fight.otx_fighter1) ? (
						<>
							<div className="win-container">
								<div className="indicator win-indicator">
									<span>win</span>
								</div>
								{/* If fight ended in money round we want to show money round icon */}
								{moneyRoundWin && (
									<div className="money-round">
										<img src={imgIconDollarSignSvg} alt="imgIconDollarSignSvg" className="money-round-icon" />
									</div>
								)}
							</div>

							{fightResult && (
								<p className="decision">
									{fightResult}
									{fight.is_overtime ? (
										<>
											<span className="hide-on-mobile"> (Overtime Round)</span>
											<span className="hide-on-desktop"> (OT)</span>
										</>
									) : (
										displayRoundInfoInResults && (
											<>
												<span> (</span>
												<span className="hide-on-mobile">Round </span>
												<span className="hide-on-desktop">R</span>
												<span>{fight.ending_round_number}</span>
												{moneyRoundWin && <span className="hide-on-mobile"> - Money Round</span>}
												<span>)</span>
											</>
										)
									)}
								</p>
							)}

							<div>
								<div className="indicator loss-indicator">
									<span>loss</span>
								</div>
							</div>
						</>
					) : (
						<>
							<div className="loss-container">
								<div className="indicator loss-indicator">
									<span>loss</span>
								</div>
							</div>

							{fightResult && (
								<p className="decision">
									{fightResult}
									{fight.is_overtime ? (
										<>
											<span className="hide-on-mobile"> (Overtime Round)</span>
											<span className="hide-on-desktop"> (OT)</span>
										</>
									) : (
										displayRoundInfoInResults && (
											<>
												<span> (</span>
												<span className="hide-on-mobile">Round </span>
												<span className="hide-on-desktop">R</span>
												<span>{fight.ending_round_number}</span>
												{moneyRoundWin && <span className="hide-on-mobile"> - Money Round</span>}
												<span>)</span>
											</>
										)
									)}
								</p>
							)}

							<div className="win-container">
								{/* If fight ended in money round we want to show money round icon */}
								{moneyRoundWin && (
									<div className="money-round">
										<img src={imgIconDollarSignSvg} alt="imgIconDollarSignSvg" className="money-round-icon" />
									</div>
								)}
								<div className="indicator win-indicator">
									<span>win</span>
								</div>
							</div>
						</>
					)}
				</div>
			)}
			{/* FIGHT CARD*/}
			<div className="fightCard-fight-card-container">
				<div className="fight-card-content">
					<div className="fightCard-fighter-container">
						<div className="fighter-image-container">
							<_FighterPhoto fighter={fighter1} opponent={fighter2} fight={fight} side="left" />
						</div>
						{/* Desktop fighter names and link (Hidden on mobile)*/}
						<div className="fighter-info-container">
							<_FighterNameAndProfile fighter={fighter1} side="left" />
						</div>
					</div>
					<div className="fight-info-container">
						<div className="fight-info">
							<div className="fight-lines">
								<div className="line"></div> {/*line */}
								{!fight.otx_winner && <h3 className="fight-state">vs</h3>}
								{fight.otx_winner && <h3 className="fight-state">Final</h3>}
								<div className="line"></div> {/*line */}
							</div>

							<div className="fight-weight-rounds">
								{fight.weight_class && <p className="weight-class">{fight.weight_class}</p>}
								{fight.number_of_rounds && <div className="number-of-rounds">{fight.number_of_rounds} rounds</div>}
							</div>
						</div>

						{(fight.youtube_url || fight.is_tournament_fight) && (
							<div className="info-buttons-container">
								{fight.youtube_url && (
									<a className="youtube-button-link" href={fight.youtube_url}>
										<FontAwesomeIcon icon={faYoutube} size="2x" className="youtube-button-icon" />
										Replay
									</a>
								)}
								{fight.is_tournament_fight && (
									<a className="bracket-button-link" href={`${RoutePaths.Tournament}`}>
										<FontAwesomeIcon icon={faTrophy} size="2x" className="bracket-button-icon" />
										Bracket
									</a>
								)}
							</div>
						)}
					</div>

					<div className="fightCard-fighter-container">
						{/* Desktop fighter names and link (Hidden on mobile) */}
						<div className="fighter-info-container">
							<_FighterNameAndProfile fighter={fighter2} side="right" />
						</div>

						<div className="fighter-image-container">
							<_FighterPhoto fighter={fighter2} opponent={fighter1} fight={fight} side="right" />
						</div>
					</div>
				</div>
				{/*MOBILE FIGHTER NAMES*/}
				<div className="mobile-fighter-info-container">
					<_FighterNameAndProfileMobile fighter={fighter1} />
					<_FighterNameAndProfileMobile fighter={fighter2} />
				</div>
			</div>
			{/* // CTA Buttons*/}
			{/* Only render when it is a future fight and on the fighter profile page */}
			{!IsFightComplete(fight) && displayFightCtaButtons && (
				<div className="cta-container">
					{fight.otx_event?.dazn_url && IsInProgress(fight.otx_event) ? (
						<a href={fight.otx_event?.dazn_url} className="cta primary">
							Watch Live
						</a>
					) : fight.otx_event?.cta_url && fight.otx_event.cta_text && IsUpcoming(fight.otx_event) ? (
						<a href={fight.otx_event?.cta_url} className="cta primary">
							{fight.otx_event.cta_text}
						</a>
					) : fight.otx_event?.vivenu_id && !isEventSoldOut && IsUpcoming(fight.otx_event) ? (
						<a href={`${Urls.VivenuEventBase}/${fight.otx_event.vivenu_id}`} className="cta primary">
							<span className="hide-on-mobile">Buy Tickets</span>
							<span className="hide-on-desktop">Tickets</span>
						</a>
					) : (
						<></>
					)}

					<a className="cta secondary" href={`${RoutePaths.Events}/${fight.otx_event_id}`}>
						<span className="hide-on-mobile">View Card</span>
						<span className="hide-on-desktop">Info</span>
					</a>
				</div>
			)}
		</section>
	);
};

export default FightCard;
